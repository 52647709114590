import { N_login_method } from '@/declaration/rds/model/type/n_login_method';
import { x_login_method } from '@/declaration/type/x_login_method';
import useQueryTo from '@/hook/router/useQueryTo';
import { T_los_form } from '@/pages/auth/login';
import { useSearchParams } from 'next/navigation';
import React, { memo, useEffect } from 'react';
import { usePrevious } from 'react-use';
import { useImmer } from 'use-immer';
import Form_login, { I_Form_login } from '../../form_login/form_login';

export interface I_Qs_form_login extends I_Form_login {}

function Qs_form_login({ className, ...rest }: I_Qs_form_login) {
  const query = useSearchParams();
  const method = query.get(x_login_method) as N_login_method;
  const [form, set_form] = useImmer<T_los_form>({
    method,
  });
  const { patch } = useQueryTo();
  const method_prev = usePrevious(form.method);

  useEffect(() => {
    if (form.method && form.method !== method_prev) {
      patch({ [x_login_method]: form.method });
    }
  }, [form, method_prev, patch]);

  useEffect(() => {
    if (method) {
      set_form({ method });
    }
  }, [method, query, set_form]);

  return <Form_login on_change={set_form} merge={{ method }} {...rest} />;
}

export default memo(Qs_form_login);
