import Form_login_qs from '@/component/form/auth/qs/qs_form_login/qs_form_login';
import Layout_public from '@/component/layout/layout_public/layout_public';
import { T_component_with_page_layout } from '@/declaration/type/t_component_with_page_layout';
import useSignRedirect from '@/hook/auth/useSignRedirect';
import { fn_get_static_props_common_ns } from '@/state/page/fn_get_static_props_common_ns';
import React, { HTMLAttributes, memo } from 'react';

export interface T_los_form {
  id_like?: string;
  password?: string;
  unique?: string;
  email?: string;
  ccode?: string;
  phone?: string;
  vcode?: string;
  method?: string;
  __dev_no_vcode?: boolean;
}

export interface I_Login extends HTMLAttributes<HTMLDivElement> {}

const Login: T_component_with_page_layout<I_Login> = memo<I_Login>(() => {
  const { run } = useSignRedirect({ auto_set_from_url: true });

  return (
    <div className="container mx-auto max-w-lg px-2" data-testid="Login">
      <Form_login_qs on_success={run} />
    </div>
  );
});

export default Login;

Login.get_layout = (page) => <Layout_public>{page}</Layout_public>;

export const getStaticProps = fn_get_static_props_common_ns(['account']);
